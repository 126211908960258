<template>
	<div class="adomany">

		<div class="full">

			<div v-show="page == 0" style="background: #F8F8F8">
				<div class="promoAdomanyHeader">
					<div class="grid">
						<div class="col-3 md:col-3 lg:col-2 lead leadHeader">Adományozz lehetőséget!</div>
					</div>
					<div class="grid">
						<div class="col-6 md:col-6 lg:col-2 lead"><div class="leadText">Válassz az alábbi lehetőségek közül, vagy add meg adományod egyedi értékét!</div></div>
					</div>
				</div>

				<div class="amountMinusMargin grid justify-content-center ">
					<div class="col-12 md:col-3 lg:col-2" id="2500amount" @click="amountChange('2500amount')" @mouseover="amountChange('2500amount')" 
						:class="{ normalAmountBox : actualAdomany != '2500amount', activeAmountBox: actualAdomany == '2500amount' }">
						<div style="text-align: center">
							<span class="amountText">2500</span><span class="amountFt"> Ft</span>
						</div>
						<div :class="{ amountAdomany : actualAdomany != '2500amount', amountAdomanyActive: actualAdomany == '2500amount' }">adomány</div>
						<div :class="{ amountPositionActive : actualAdomany != '2500amount', amountPosition: actualAdomany == '2500amount' }">
							<div class="box">
								<button @click="amountClick('2500amount')" class="p-button p-component mr-2 mb-2 amountButton" type="button"
									:class="{ amountButtonActive : actualAdomany == '2500amount', amountButton: actualAdomany != '2500amount',
									'p-disabled': actualAdomany != '2500amount' }">
									<span class="p-button-label" :class="{ amountButtonTextActive : actualAdomany == '2500amount'}">Kosárba</span>
								</button>
							</div>
						</div>
					</div>
					<div class="col-12 md:col-3 lg:col-2" id="10000amount" @click="amountChange('10000amount')" @mouseover="amountChange('10000amount')"
						:class="{ normalAmountBox : actualAdomany != '10000amount', activeAmountBox: actualAdomany == '10000amount' }">
						<div style="text-align: center">
							<span class="amountText">10000</span><span class="amountFt"> Ft</span>
						</div>
						<div :class="{ amountAdomany : actualAdomany != '10000amount', amountAdomanyActive: actualAdomany == '10000amount' }">adomány</div>
						<div :class="{ amountPositionActive : actualAdomany != '10000amount', amountPosition: actualAdomany == '10000amount' }">
							<div class="box">
								<button @click="amountClick('10000amount')" class="p-button p-component mr-2 mb-2 amountButton" type="button" 
									:class="{ amountButtonActive : actualAdomany == '10000amount', amountButton: actualAdomany != '10000amount',
									'p-disabled': actualAdomany != '10000amount' }">
									<span class="p-button-label" :class="{ amountButtonTextActive : actualAdomany == '10000amount'}">Kosárba</span>
								</button>
							</div>
						</div>
					</div>
					<div class="col-12 md:col-3 lg:col-2" id="50000amount" @click="amountChange('50000amount')" @mouseover="amountChange('50000amount')"
						:class="{ normalAmountBox : actualAdomany != '50000amount', activeAmountBox: actualAdomany == '50000amount' }">
						<div style="text-align: center">
							<span class="amountText">50000</span><span class="amountFt"> Ft</span>
						</div>
						<div :class="{ amountAdomany : actualAdomany != '50000amount', amountAdomanyActive: actualAdomany == '50000amount' }">adomány</div>
						<div :class="{ amountPositionActive : actualAdomany != '50000amount', amountPosition: actualAdomany == '50000amount' }">
							<div class="box">
								<button @click="amountClick('50000amount')" class="p-button p-component mr-2 mb-2" type="button" 
									:class="{ amountButtonActive : actualAdomany == '50000amount', amountButton : actualAdomany != '50000amount',
									'p-disabled': actualAdomany != '50000amount' }">
									<span class="p-button-label" :class="{ amountButtonTextActive : actualAdomany == '50000amount'}">Kosárba</span>
								</button>
							</div>
						</div>
					</div>
					<div class="col-12 md:col-3 lg:col-2" id="individualamount" @click="amountChange('individualamount')" @mouseover="amountChange('individualamount')"
						:class="{ normalAmountBox : actualAdomany != 'individualamount', activeAmountBox: actualAdomany == 'individualamount' }">
						<div class="box">
							<span><input v-model.number="amountNum" class="p-inputtext p-component adomanyosszeg" @keypress="isNumber($event)" type="number" placeholder="Egyedi összeg" /></span>
							<span class="uniqueAmountFt"> Ft</span>
						</div>
						<div :class="{ amountAdomany : actualAdomany != 'individualamount', amountAdomanyActive: actualAdomany == 'individualamount' }">adomány</div>
						<div :class="{ amountPositionActive : actualAdomany != 'individualamount', amountPosition: actualAdomany == 'individualamount' }">
							<div class="box">
								<button @click="amountClick('individualamount')" class="p-button p-component mr-2 mb-2" type="button" 
									:class="{ amountButtonActive : (actualAdomany == 'individualamount' && amountNum+0 > 0), 
										amountButton: actualAdomany != 'individualamount',
										'p-disabled': (actualAdomany != 'individualamount' || amountNum+0 < 1) }">
									<span class="p-button-label" :class="{ amountButtonTextActive : (actualAdomany == 'individualamount' && amountNum+0 > 0) }">Kosárba</span>
								</button>
							</div>
						</div>				
					</div>
				</div>
				<div class="grid justify-content-center">
					<div class="col-12 md:col-10 lg:col-8 contentText">
						<div class="adomanyTitle">
							Adományozás
						</div>
						<div class="adomanyText">
							Immár több mint harminc éve segítjük a bajba jutott, rászoruló embereket, gyermekes családokat, idős és beteg embereket. 
							A Váci Egyházmegyei Karitász minden segítséget kérő személyt emberi méltóságát tiszteletben tartva testvéri szeretettel fogad, 
							aminek alapja az a meggyőződésünk, hogy mint Isten gyermekei mindannyian testvérek vagyunk származástól, vallástól, 
							világnézeti meggyőződéstől függetlenül. Több száz önkéntesünkkel együtt a hívek adományaiból, 
							az egyházmegye és a jó szándékú emberek támogatásaiból évente több mint húszezer emberhez jut el segítségünk a 
							Váci Egyházmegye területén és a határon túl, ahol éppen segítséget kérnek tőlünk. Munkánk során a gyorsaságra, 
							a megfelelő és helyzethez illő segítségre és a velünk együttműködő közösségekre, szervezetekre és magánemberek önzetlen munkájára támaszkodunk, 
							amellyel minden kapott forint értékét megtöbbszörözzük. Segítő munkánk összekötő szövete a Szeretet, 
							amiben mindannyian egymásra találunk és mindannyian egyenlők vagyunk: segítők és segítettek egyaránt.
						</div>
						<div class="adomanyTitle">
							Támogatásával ezen a nyáron az alábbi céljaink megvalósulásához járulhat hozzá:
						</div>						
						<table class="adomanyDescripionTitle">
							<tr>
								<td class="adomanyDescriptionIcon"></td>
								<td class="adomanyDescription">
									Iskolakezdéshez szükséges kiadásokra és tanszerekre többgyermekes családok és szegény gyerekek számára.
								</td>
							</tr>
							<tr>
								<td class="adomanyDescriptionIcon"></td>
								<td class="adomanyDescription">					
									Beteg, fogyatékossággal élő gyermekeket nevelő családok megsegítése.
								</td>
							</tr>
							<tr>
								<td class="adomanyDescriptionIcon"></td>
								<td class="adomanyDescription">
									Tűz és viharkár miatt megrongálódott otthonok újjáépítése.
								</td>
							</tr>
						</table>
						<div class="adomanyText">
							Kérjük, kísérje figyelemmel weboldalunkat és Facebook oldalunkat, hogy értesüljön programjainkról, amihez Ön is hozzájárulhat támogatásával.
						</div>
						<div class="adomanyText">
							<p>
								<a href="https://caritas.vaciegyhazmegye.hu/">https://caritas.vaciegyhazmegye.hu/</a>
							</p>
							<p>
								<a href="https://www.facebook.com/karitaszvac">https://www.facebook.com/karitaszvac</a>
							</p>
							<p></p>
						</div>
					</div>

				</div>
			</div>


			<div v-show="page == 1">
				<div class="promoPaymentMethodHeader">
					<div class="grid">
						<div class="col-3 md:col-3 lg:col-2 lead leadHeader">
							<button @click="backButton" class="p-button p-component p-button-rounded mr-2 mb-2" type="button">
								<span class="p-button-label">Vissza</span>
							</button>
						</div>
					</div>
				</div>
				<div class="grid justify-content-center">
					<div class="col-8 md:col-8 lg:col-8">
						<div class="paymentText">Kérjük válasszon fizetési módot:</div>
					</div>
				</div>

				<div class="flex flex-wrap justify-content-center choiceRow">
					<div class="mr-2 mb-2"> 
						<div class="choiceImg col-12 md:col-12">
							<img src="/images/cardtransfer.png" class="imgheight" :class="{borderImg: transfer == 'card'}" />
						</div>
						<div class="col-12 md:col-12" style="margin-top: 1rem;">
							<div class="choiceText">
								<label for="option1">Bankkártya</label>
							</div>
							<div class="field-radiobutton choiceButton">
								<RadioButton id="option1" name="transfername" value="card" v-model="transfer" @click="cardClick()"/>
							</div>
						</div>
					</div>
					<div class="mr-2 mb-2">
						<div class="choiceImg col-12 md:col-12">
							<img src="/images/banktransfer.png" class="imgheight" :class="{borderImg: transfer == 'bank'}" />
						</div>
						<div class="col-12 md:col-12" style="margin-top: 1rem;">
							<div class="choiceText">
								<label for="option2">Banki átutalás</label>
							</div>
							<div class="field-radiobutton choiceButton">
								<RadioButton id="option2" name="transfername" value="bank" v-model="transfer" />
							</div>
						</div>
					</div>
				</div>
				
				<div class="grid justify-content-center choiceRow" v-show="transfer == 'bank'">
					<div class="col-4 md:col-3 lg:col-3"> 
						<div class="col-12 md:col-12">
							<div class="choiceText">
								<label for="option3">Egyszeri</label>
							</div>
							<div class="field-radiobutton choiceButton">
								<RadioButton id="option3" name="transfertimename" value="Egyszeri" v-model="transfertime" />
							</div>
						</div>
					</div>
					<div class="col-4 md:col-3 lg:col-3">
						<div class="col-12 md:col-12">
							<div class="choiceText">
								<label for="option4">Rendszeres</label>
							</div>
							<div class="field-radiobutton choiceButton">
								<RadioButton id="option4" name="transfertimename" value="Rendszeres" v-model="transfertime" />
							</div>
						</div>
					</div>
				</div>

				<div class="grid justify-content-center lineHeight">
					<div class="col-10 md:col-10 lg:col-10 lineType">
					</div>
				</div>

				<div class="grid justify-content-center">
					<div class="col1">
						<div style="margin-left:auto;margin-right:auto;">
							<Button @click="nextButton" icon="pi pi-arrow-right" class="p-button-rounded p-button-outlined mr-2 mb-2 nextButton" />
						</div>
					</div>
				</div>

				<div class="grid justify-content-center">
					<div class="col-8 md:col-8 lg:col-8">
						<div class="paymentText" v-show="transfer=='card'">Tovább az információhoz</div>
						<div class="paymentText" v-show="transfer=='bank'">Tovább az információkhoz</div>
					</div>
				</div>

				<div class="flex flex-wrap justify-content-center choiceRow">
					<div class="mr-2 md-2"> 
						<div class="">
							<div class="field-radiobutton choiceButton">
								<RadioButton id="flow1" name="flow1" value="1" v-model="flowmodelFizetes" :disabled="true" />
							</div>
						</div>
						<div class="choiceText">
							<label for="flow1">Fizetési mód</label>
						</div>
					</div>
					<div class="mr-2 md-2"> 
						<div class="">
							<div class="field-radiobutton choiceButton">
								<RadioButton id="flow2" name="flow2" value="2" v-model="flowmodelInformacio" :disabled="true" />
							</div>
						</div>
						<div class="choiceText">
							<label for="flow2">Információ</label>
						</div>
					</div>
					<div class="mr-2 md-2" v-show="transfer == 'card'"> 
						<div class="">
							<div class="field-radiobutton choiceButton">
								<RadioButton id="flow3" name="flow3" value="2" v-model="flowmodelMegerosites" :disabled="true" />
							</div>
						</div>
						<div class="choiceText">
							<label for="flow3">Megerősítés</label>
						</div>
					</div>
				</div>

				<div class="grid justify-content-center lineHeight" v-show="transfer == 'card'">
					<div class="col-6 md:col-6 lg:col-6 lineType minusLine" > </div>
				</div>

				<div class="grid justify-content-center lineHeight" v-show="transfer == 'bank'">
					<div class="col-3 md:col-3 lg:col-3 lineType minusLine"> </div>
				</div>

				<div class="grid justify-content-center lineHeight">
					<div class="col-10 md:col-10 lg:col-10 lineType">
					</div>
				</div>

				<div class="flex flex-wrap justify-content-center">
					<div class="md-2 mr-2 paymentText">Kosár összegzése:</div>
					<div class="md-2 mr-2 sumAmount"><span>{{ amountTransfer }}</span><span class="sumFt">Ft</span></div>
					<div class="md-2 mr-2 paymentText">{{ transfertime }} adomány</div>
				</div>

				<div class="grid justify-content-center lineHeight">
					<div class="col-10 md:col-10 lg:col-10 lineType">
					</div>
				</div>
			</div>

			<div v-show="page == 2">
				<div class="promoPaymentMethodHeader2">
					<div class="grid">
						<div class="col-3 md:col-3 lg:col-2 lead leadHeader">
							<button @click="backButton" class="p-button p-component p-button-rounded mr-2 mb-2" type="button">
								<span class="p-button-label">Vissza</span>
							</button>
						</div>
					</div>
				</div>

				<div class="grid justify-content-center">
					<div class="col-8 md:col-8 lg:col-8">
						<div class="paymentText">Információk</div>
					</div>

					<div class="col-8 md:col-8 lg:col-8">
						<p class="tevekenysegInfo">
							Amennyiben kapcsolatba szeretne kerülni velünk igazolás kérés, vagy az adományozással kapcsolatos más kérdésben, kérjük, hogy írjon az adomany@vaciegyhazmegye.hu email címre.
							<InputText type="hidden" placeholder="Email cím" name="email" value="csoma.peter@vaciegyhazmegye.hu" v-model="email" />
						</p>
					</div>
				</div>


				<div class="grid justify-content-center lineHeight">
					<div class="col-10 md:col-10 lg:col-10 lineType">
					</div>
				</div>

				<div class="grid justify-content-center">
					<div class="col1">
						<div style="margin-left:auto;margin-right:auto;">
							<Button @click="nextButton" icon="pi pi-arrow-right" class="p-button-rounded p-button-outlined mr-2 mb-2 nextButton"/>
						</div>
					</div>
				</div>

				<div class="grid justify-content-center">
					<div class="col-8 md:col-8 lg:col-8">
						<div class="paymentText">Tovább a fizetés megerősítéséhez</div>
					</div>
				</div>

				<div class="flex flex-wrap justify-content-center choiceRow">
					<div class="mr-2 md-2"> 
						<div class="">
							<div class="field-radiobutton choiceButton">
								<RadioButton id="flow4" name="flow4" value="1" v-model="flowmodelFizetes" :disabled="true" />
							</div>
						</div>
						<div class="choiceText">
							<label for="flow4">Fizetési mód</label>
						</div>
					</div>
					<div class="mr-2 md-2"> 
						<div class="">
							<div class="field-radiobutton choiceButton">
								<RadioButton id="flow5" name="flow5" value="1" v-model="flowmodelInformacio" :disabled="true" />
							</div>
						</div>
						<div class="choiceText">
							<label for="flow5">Információ</label>
						</div>
					</div>
					<div class="mr-2 md-2"> 
						<div class="">
							<div class="field-radiobutton choiceButton">
								<RadioButton id="flow6" name="flow6" value="1" v-model="flowmodelMegerosites" :disabled="true"  />
							</div>
						</div>
						<div class="choiceText">
							<label for="flow6">Megerősítés</label>
						</div>
					</div>
				</div>

				<div class="grid justify-content-center lineHeight">
					<div class="col-6 md:col-6 lg:col-6 lineType minusLine"> 
					</div>
				</div>			

				<div class="grid justify-content-center lineHeight">
					<div class="col-10 md:col-10 lg:col-10 lineType">
					</div>
				</div>

				<div class="flex flex-wrap justify-content-center">
					<div class="md-2 mr-2 paymentText">Kosár összegzése:</div>
					<div class="md-2 mr-2 sumAmount"><span>{{ amountTransfer }}</span><span class="sumFt">Ft</span></div>
					<div class="md-2 mr-2 paymentText">{{ transfertime }} adomány</div>
				</div>

				<div class="grid justify-content-center lineHeight">
					<div class="col-10 md:col-10 lg:col-10 lineType">
					</div>
				</div>
			</div>

			<div v-show="page == 3">
				<div class="promoPaymentMethodHeader">
					<div class="grid">
						<div class="col-3 md:col-3 lg:col-2 lead leadHeader">
							<button @click="backButton" class="p-button p-component p-button-rounded mr-2 mb-2" type="button">
								<span class="p-button-label">Vissza</span>
							</button>
						</div>
					</div>
				</div>

				<div class="grid justify-content-center">
					<div class="col-8 md:col-8 lg:col-8">
						<div class="paymentText">Fizetés megerősítése</div>
					</div>
					<div class="col-8 md:col-8 lg:col-8">
						<div class="TIClass">
							<form id="payment" action="/api/payment" method="post">
								<input name="amount-payment" :value="amountTransfer" type="hidden" />
								<input name="email-payment" value="caritas@vaciegyhazmegye.hu" type="hidden" />
								<Button type="submit" label="Fizetés" class="paymentButtonAccept p-button-rounded p-button-danger mr-4 mb-4" />
							</form>
						</div>
					</div>
				</div>

				<div class="grid justify-content-center lineHeight">
					<div class="col-10 md:col-10 lg:col-10 lineType">
					</div>
				</div>

				<div class="flex flex-wrap justify-content-center choiceRow">
					<div class="mr-2 md-2"> 
						<div class="">
							<div class="field-radiobutton choiceButton">
								<RadioButton id="flow7" name="flow7" value="1" v-model="flowmodelFizetes" :disabled="true" />
							</div>
						</div>
						<div class="choiceText">
							<label for="flow7">Fizetési mód</label>
						</div>
					</div>
					<div class="mr-2 md-2"> 
						<div class="">
							<div class="field-radiobutton choiceButton">
								<RadioButton id="flow8" name="flow8" value="1" v-model="flowmodelInformacio" :disabled="true" />
							</div>
						</div>
						<div class="choiceText">
							<label for="flow8">Információ</label>
						</div>
					</div>
					<div class="mr-2 md-2"> 
						<div class="">
							<div class="field-radiobutton choiceButton">
								<RadioButton id="flow9" name="flow9" value="1" v-model="flowmodelMegerosites" :disabled="true"  />
							</div>
						</div>
						<div class="choiceText">
							<label for="flow9">Megerősítés</label>
						</div>
					</div>
				</div>

				<div class="grid justify-content-center lineHeight">
					<div class="col-6 md:col-6 lg:col-6 lineType minusLine"> 
					</div>
				</div>			

				<div class="grid justify-content-center lineHeight">
					<div class="col-10 md:col-10 lg:col-10 lineType">
					</div>
				</div>

				<div class="flex flex-wrap justify-content-center">
					<div class="md-2 mr-2 paymentText">Kosár összegzése:</div>
					<div class="md-2 mr-2 sumAmount"><span>{{ amountTransfer }}</span><span class="sumFt">Ft</span></div>
					<div class="md-2 mr-2 paymentText">{{ transfertime }} adomány</div>
				</div>

				<div class="grid justify-content-center lineHeight">
					<div class="col-10 md:col-10 lg:col-10 lineType">
					</div>
				</div>
			</div>

			<div v-show="page == 4">
				<div class="promoPaymentMethodHeader">
					<div class="grid">
						<div class="col-3 md:col-3 lg:col-2 lead leadHeader">
						</div>
					</div>
				</div>

				<div class="grid justify-content-center">
					<div class="col-8 md:col-8 lg:col-8">
						<div class="paymentText">Információk</div>
					</div>
					<div class="col-8 md:col-8 lg:col-8">

						<div class="tevekenysegBorder">
							<p class="tevekenysegInfo">&nbsp;
								</p>
							<p class="tevekenysegInfo">
								Köszönettel fogadtuk a banki utalási szándékát! Kérjük, saját bankjánál állítsa be a rögzített utalást és a lenti adatok alapján juttassa el adományát részünkre!
							</p>
							<p class="tevekenysegInfo">
								Amennyiben a megjegyzés rovatába beírja az email címét, úgy tájékoztatni tudjuk az adománya sorsáról!
							</p>
							<p class="tevekenysegInfo">
								Amennyiben kapcsolatba szeretne kerülni velünk igazolás kérés, vagy az adományozással kapcsolatos más kérdésben, kérjük, hogy írjon az adomany@vaciegyhazmegye.hu email címre.
							</p>

							<table class="adomanyDescripionTitle">
								<tr class="adomanyDescriptionRow">
									<td class="adomanyDescriptionIcon2" width="10%">
									</td>
									<td class="adomanyDescription2" width="30%"> 
										<div>Kedvezményezett:</div>
									</td>
									<td class="adomanyDescription2" colspan="3">
										<div>Váci Egyházmegyei Karitász</div>
									</td>
								</tr>
								<tr class="adomanyDescriptionRow">
									<td class="adomanyDescriptionIcon2" width="5%">
									</td>
									<td class="adomanyDescription2" width="50%"> 
										<div>Számlaszám:</div>
									</td>
									<td class="adomanyDescription2" width="45%">
										<div>11784009-22227148-00000000</div>
									</td>
								</tr>
								<tr class="adomanyDescriptionRow">
									<td class="adomanyDescriptionIcon2" width="5%">
									</td>
									<td class="adomanyDescription2" width="50%">
										<div>Külföldről történő utalás esetén:</div>
									</td>
									<td class="adomanyDescription2" width="45%">
										<div>SWIFT</div>
										<div>HU42117840092222714800000000</div>
										<div>&nbsp;</div>
										<div>IBAN</div>
										<div>OTPVHUHB</div>
									</td>
								</tr>
								<tr class="adomanyDescriptionRow">
									<td class="adomanyDescriptionIcon2" width="5%">
									</td>
									<td class="adomanyDescription2" width="50%">
										<div>Utalás összege:</div>
									</td>
									<td class="adomanyDescription2" width="45%">
										<div>{{ amountTransfer }}</div>
									</td>
								</tr>
								<tr class="adomanyDescriptionRow">
									<td class="adomanyDescriptionIcon2" width="5%">
									</td>
									<td class="adomanyDescription2" width="50%">
										<div>Utalás gyakorisága:</div>
									</td>
									<td class="adomanyDescription2" width="45%">
										<div>{{ transfertime }}</div>
									</td>
								</tr>								
								<tr class="adomanyDescriptionRow">
									<td class="adomanyDescriptionIcon2" width="5%">
									</td>
									<td class="adomanyDescription2" width="50%">
										<div>Utalás Közleménye:</div>
									</td>
									<td class="adomanyDescription2" width="45%">
										<div>Email cím, ahol értesíteni tudjuk az adomány sorsáról!</div>
										<div>Utalás célja pl. "ukrajnai menekültek"</div>
									</td>
								</tr>								
							</table>
						</div>
					</div>
				</div>

				<div class="flex flex-wrap justify-content-center choiceRow">
					<div class="mr-2 md-2"> 
						<div class="">
							<div class="field-radiobutton choiceButton">
								<RadioButton id="flow1" name="flow1" value="1" v-model="flowmodelFizetes" :disabled="true" />
							</div>
						</div>
						<div class="choiceText">
							<label for="flow1">Fizetési mód</label>
						</div>
					</div>
					<div class="mr-2 md-2"> 
						<div class="">
							<div class="field-radiobutton choiceButton">
								<RadioButton id="flow2" name="flow2" value="1" v-model="flowmodelInformacio" :disabled="true" />
							</div>
						</div>
						<div class="choiceText">
							<label for="flow2">Információ</label>
						</div>
					</div>
				</div>

				<div class="grid justify-content-center lineHeight">
					<div class="col-3 md:col-3 lg:col-3 lineType minusLine"> </div>
				</div>

				<div class="grid justify-content-center lineHeight">
					<div class="col-10 md:col-10 lg:col-10 lineType">
					</div>
				</div>

			</div>
		</div>


		<div class="grid">
			<div class="col-12 md:col-12 lg:col-12 adomanyFooter">
				<AppFooter />
			</div>
		</div>			
	</div>
</template>

<script>
import AppFooter from '../AppFooter.vue';

export default {
	data() {
		return {
			actualAdomany: 'individualamount',
			amountNum: '',
			page: 0,
			amountTransfer: 0,
			transfer: 'card',
			transfertime: 'Egyszeri',
			flowmodel: 1,
			flowmodelFizetes: '1',
			flowmodelMegerosites: '0',
			flowmodelInformacio: '0',
			emailTransfer: 'caritas@vaciegyhazmegye.hu',
			email: '',
		}
	},
    components: {
        'AppFooter': AppFooter,
    },
	mounted() {
		/*
		console.log("ROute: "+this.$route.query);
		*/
	},	
	methods: {
		amountChange(id) {
			this.actualAdomany = id
		},
		isNumber: function(evt) {
			evt = (evt) ? evt : window.event;
			
			var charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		amountClick(buttonName) {
			if (buttonName == "2500amount") {
				this.amountTransfer = 2500;
			} else if (buttonName == "10000amount") {
				this.amountTransfer = 10000;
			} else if (buttonName == "50000amount") {
				this.amountTransfer = 50000;
			} else {
				this.amountTransfer = this.amountNum;
			}
			this.page = 1;
			var element = document.getElementById("layoutInfo");
			element.scrollTop = 0;
			// var alma = this.$refs["#layoutInfo"]
			// this.$refs.layoutInfo
			this.$appState.myCart = false;
		},
		backButton() {
			if (this.page > 0) {
				this.page--;
			}
			if (this.page == 0) {
				this.$appState.myCart = true;
			}
			if (this.page < 2) {
				this.flowmodelInformacio = "0";
			}
			if (this.page < 3) {
				this.flowmodelMegerosites = "0";
			}
			if (this.page == 3) {
				this.page = 1;
			}
			var element = document.getElementById("layoutInfo");
			element.scrollTop = 0;
		},
		nextButton() {
			if (this.page < 3) {
				this.page++;
			}
			if (this.page > 1) {
				this.flowmodelInformacio = "1";
			}
			if (this.page > 2) {
				this.flowmodelMegerosites = "1";
			}
			if (this.page == 2 && this.transfer=='bank') {
				this.page=4;
			}
			if (this.page == 3) {
				this.emailTransfer = "caritas@vaciegyhazmegye.hu"; // this.email
			}
			var element = document.getElementById("layoutInfo");
			element.scrollTop = 0;
		},
		cardClick() {
			this.transfertime = "Egyszeri";
			window.scrollTo(0,0);
		},
	}
}
</script>

<style lang="scss" scoped>
	.adomanyText {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 109.69%;
		color: #939090;
		margin-bottom: 2rem;
	}

</style>
