<template>
	<div class="flex align-items-center p-4 shadow-2 adomanyFooter">
		<div style="float: left">
			<router-link class="layout-topbar-logo" to="/">
				<div class="header-logo adomanyLogoFooter">
					<div>Váci</div>
					<div>Egyházmegye</div>
					<div>Karitász</div>
				</div>
			</router-link>
		</div>
		<div style="width: 100%; float: right">
			<div class="footerMenu">
				<a href="https://caritas.vaciegyhazmegye.hu/Vaci_Egyhazmegye_Karitasz/adatvedelem.html" class="layout-topbar-logo">
					<div class="adomanyFooterText">Adatvédelem</div>
				</a>
			</div>
			<div class="footerMenu">
				<router-link class="layout-topbar-logo" to="/kapcsolat">
					<div class="adomanyFooterText">Kapcsolat</div>
				</router-link>
			</div>
			<div class="footerMenu">
				<router-link class="layout-topbar-logo" to="/rolunk">
					<div class="adomanyFooterText">Rólunk</div>
				</router-link>
			</div>
			<div class="footerMenu">
				<router-link class="layout-topbar-logo" to="/">
					<div class="adomanyFooterText">Adomány</div>
				</router-link>
			</div>

			<div class="footerMenu">
				<router-link class="layout-topbar-logo" to="/">
					<div class="adomanyFooterText">Kezdőlap</div>
				</router-link>
			</div>
			<div class="barion footerMenu">
				<a href="https://www.barion.com">
					<div class="barionLogo" style="margin-top: 10px;">
						<img src="images/barion.png" />
					</div>
					<div class="barionASZF" style="color: #FFFFFF">
						Az online bankkártyás fizetések a Barion rendszerén keresztül valósulnak meg. <br />
						A bankkártya adatok a kereskedőhöz nem jutnak el. <br />
						A szolgáltatást nyújtó Barion Payment Zrt. a Magyar Nemzeti Bank felügyelete alatt álló intézmény,<br />
						engedélyének száma: H-EN-I-1064/2013.
					</div>
				</a>
			</div>
		</div>
    </div>
</template>

<script>
	export default {
		name: "AppFooter",
		props: {
			layoutMode: {
				type: String,
				default: 'light'
			}
		},
		computed: {
			isRTL() {
				return this.$appState.RTL;
			}
		}
	}
</script>

<style scoped>
div.align-items-center {
	align-items: start!important;
}
</style>